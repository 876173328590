





































































































































import { Component, Mixins } from "vue-property-decorator";
import { UserInterface } from "@/types/user.type";
import { UserModule } from "@/store/modules/user-module";
import { ProjectModule } from "@/store/modules/project-module";
import { CombinedPositionInterface } from "@/types/project.type";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import { CreateProjectMemberInterface, ProjectRolesInterface } from "@/types/project-member.type";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { dayToSecond, secondToDay } from "@/utils/unitConverstion";
import { ProjectUserInterface } from "@/types/project.type";
import { RoleMixin } from "@/mixins/role-mixin";
import SaveButton from "@/components/buttons/SaveButton.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

@Component({
  components: {
    SaveButton,
    CloseButton,
  },
})
export default class MemberForm extends Mixins(DurationHelperMixins, RoleMixin) {
  get users(): UserInterface[] {
    return UserModule.users;
  }
  get positions(): CombinedPositionInterface[] {
    return ProjectModule.project.combinedPositions ? ProjectModule.project.combinedPositions : [];
  }

  get member(): ProjectUserInterface {
    return ProjectMemberModule.projectMember;
  }

  get roles(): ProjectRolesInterface[] {
    return ProjectMemberModule.projectRoles;
  }

  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }

  public $refs!: {
    form: HTMLFormElement;
  };

  private user = -1;
  private maxUnit = 0;
  private costPerUnit = 0;
  private selectedPosition = 0;
  private billedDuration = 0;
  private defaultBilledDuration = 0;
  private valid = false;
  private editing = false;
  private billable = true;
  private limit = false;
  private selectRole = -1;

  private noneEmptyRule = [(v: string) => !!v || "This field is required"];
  private noneEmptyUserRule = [(v: number) => v >= 0 || "This field is required"];
  private zeroAndNoneEmptyRule = [(v: string) => !!String(v) || "This field is required"];
  public async mounted() {
    await ProjectMemberModule.getIsManager(Number(this.$route.params.id));
  }

  private async created() {
    await UserModule.getUsers();
    await ProjectModule.getProjectById(Number(this.$route.params.id));
    if (this.$route.params.uid) {
      this.editing = true;
      await ProjectMemberModule.getMembersById(Number(this.$route.params.uid));
      this.user = this.member.user;
      this.selectedPosition = this.member.position;
      this.costPerUnit = this.member.costPerUnit;
      this.limit = this.member.noMaximumLimit;
      this.maxUnit = secondToDay(this.member.maximumUnit);
      this.billedDuration = secondToDay(this.member.billedDuration);
      this.defaultBilledDuration = secondToDay(this.member.defaultBilledDuration);
      this.selectRole = this.member.projectRole;
      this.billable = this.member.billable;
    }
  }

  private async addMember() {
    this.$refs.form.validate();
    if (this.valid) {
      const data = {} as CreateProjectMemberInterface;
      data.project = Number(this.$route.params.id);
      data.user = Number(this.user);
      data.costPerUnit = Number(this.costPerUnit);
      data.noMaximumLimit = this.limit;
      this.limit ? (data.maximumUnit = dayToSecond(0)) : (data.maximumUnit = dayToSecond(Number(this.maxUnit)));
      data.maximumUnit = dayToSecond(Number(this.maxUnit));
      data.position = Number(this.selectedPosition);
      data.id = Number(this.$route.params.uid);
      data.billedDuration = dayToSecond(Number(this.billedDuration));
      data.projectRole = this.selectRole;
      data.billable = this.billable;
      if (this.editing) {
        await ProjectMemberModule.updateProjectMember(data);
      } else {
        await ProjectMemberModule.addProjectMember(data);
      }
    }
  }

  private cancel() {
    this.$router.push(`/project/${this.$route.params.id}`);
  }
}
