
















import { Component, Prop, Vue } from "vue-property-decorator";
import { ProjectInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import MemberForm from "@/components/member/MemberForm.vue";
import ProjectBreadcrumb from "@/components/project/ProjectBreadcrumb.vue";
import { ErrorModule } from "@/store/modules/error";
import NotFound from "@/components/NotFound.vue";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import Header from "@/components/Header.vue";

@Component({
  components: {
    MemberForm,
    ProjectBreadcrumb,
    NotFound,
    Header,
  },
})
export default class NewMember extends Vue {
  @Prop({
    required: true,
  })
  private readonly id!: number;

  private projectData: ProjectInterface = {
    id: undefined,
    client: undefined,
    name: "",
    remark: "",
    contactPersonName: "",
    contactPersonEmail: "",
    billedDuration: 0,
    totalDuration: 0,
    startDate: "",
    canEdit: false,
    tags: [],
    autoApproveTimesheet: false,
    alertCount: 0,
    billable: false,
    approvedDuration: 0,
    rejectedDuration: 0,
    pendingDuration: 0,
  };

  private async created() {
    await ProjectModule.getProjectById(this.id as number);
    await ProjectMemberModule.getProjectRoles();
    this.projectData = { ...(ProjectModule.project as ProjectInterface) };
  }

  get isNotFound(): boolean {
    return ErrorModule.isNotFound;
  }
}
